import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import * as S from './ImageSlider.css'

const ImageSlider = ({ images }) => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    variableWidth: true,
    focusOnSelect: false,
    autoplay: true,
    speed: 800,
    autoplaySpeed: 6000,
    draggable: false,
    customPaging: function (i) {
      return (
        <S.Indicator role="button" tabIndex={0}>
          0{i + 1}
          <span>&nbsp;__</span>
        </S.Indicator>
      )
    },
  }

  return (
    <S.ImageSlider>
      <S.CarouselWrap>
        <Slider {...settings}>
          {images.map((image, key) => (
            <S.SliderItem key={key}>
              <img src={image.publicURL} alt="" />
            </S.SliderItem>
          ))}
        </Slider>
      </S.CarouselWrap>
    </S.ImageSlider>
  )
}

export default ImageSlider
