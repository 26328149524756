import React, { useState } from 'react'
import styled from 'styled-components'
import htmr from 'htmr'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Head from '../components/Projects/Details/Head'
import Hero from '../components/Global/Hero'
import BlockQuote from '../components/Global/BlockQuote'
import { Layout, Container } from '../layouts'
import { nl2br } from '../../lib/helpers'
import ImageSlider from '../components/Projects/ImageSlider'
import Video from '../components/Global/Video'

import * as S from '../components/Projects/Details/Details.css'

const ProjectPost = ({ data }) => {
  const { html, frontmatter } = data.markdownRemark
  const {
    client,
    category,
    project_title,
    meta_title,
    meta_description,
    url,
    client_logo,
    description,
    testimonial,
    project_video,
    video,
  } = frontmatter
  const deviceImages = frontmatter.images
    ? [frontmatter.images[0]?.publicURL]
    : []
  const [screenshot, setScreenshot] = useState(0)

  const prev = () => {
    if (screenshot === 0) setScreenshot(deviceImages.length - 1)
    else setScreenshot(screenshot - 1)
  }

  const next = () => {
    if (screenshot === deviceImages.length - 1) setScreenshot(0)
    else setScreenshot(screenshot + 1)
  }

  return (
    <Layout>
      <Helmet>
        <title>{meta_title} | TechEquipt</title>
        <meta name="description" content={meta_description} />
      </Helmet>

      <Hero title={`Project: ${project_title}`} small />

      <Container skinny>
        <Head
          projectCategory={category}
          projectName={project_title}
          projectUrl={url}
          clientLogo={client_logo && client_logo?.publicURL}
          clientName={client}
        />

        <S.Description hasVideo={video?.video_url}>
          {nl2br(description)}
        </S.Description>

        {video?.video_url && (
          <Video
            url={video.video_url}
            fullWidth
            thumbnail={video?.video_thumbnail?.publicURL}
            rounded
          />
        )}
      </Container>

      {frontmatter.images?.length > 0 ? (
        <ImageSlider images={frontmatter.images} />
      ) : null}

      {testimonial && (
        <Container skinny>
          <BlockQuote fontSize="1.25rem" hasShadow>
            {nl2br(testimonial)}
          </BlockQuote>
        </Container>
      )}

      {project_video && (
        <Container skinny>
          <Video url={project_video} fullWidth rounded />
        </Container>
      )}

      <Container skinny>
        <S.Content>{html && htmr(html)}</S.Content>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        description
        templateKey
        slug
        category
        feature_image {
          publicURL
        }
        images {
          publicURL
        }
        client_logo {
          publicURL
        }
        url
        project_video
        project_title
        meta_title
        meta_description
        client
        date(formatString: "MMMM Do, YYYY")
        testimonial
        video {
          video_url
          video_thumbnail {
            publicURL
          }
        }
        tags
        colour
      }
    }
  }
`

export default ProjectPost
