import React from 'react'

export const nl2br = (string) =>
  string.split('\n').map((line, i) => (
    <span key={i}>
      {line}
      <br />
    </span>
  ))

export const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export const sendForm = async (name, data) => {
  if (!name || !data) return

  const submit = await fetch('/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: encode({ 'form-name': name, ...data }),
  })
  return submit
}
