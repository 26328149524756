import React from 'react'
import * as S from './Head.css'
import { Container } from '../../../../layouts'
import { colors } from '../../../../../lib/constants'

const Head = ({
  projectCategory,
  projectName,
  projectUrl,
  clientName,
  clientLogo,
}) => {
  return (
    <S.Head>
      <S.ProjectInfo>
        {projectCategory && (
          <S.ProjectCategory>{projectCategory}</S.ProjectCategory>
        )}
        <S.ProjectName>{projectName}</S.ProjectName>
        {projectUrl && (
          <S.ProjectLink to={projectUrl} target="_blank">
            Visit website
          </S.ProjectLink>
        )}
      </S.ProjectInfo>

      <S.ClientInfo>
        {clientLogo && <S.ClientLogo src={clientLogo} alt={clientName} />}
      </S.ClientInfo>
    </S.Head>
  )
}

Head.propTypes = {}

Head.defaultProps = {}

export default Head
