import styled from 'styled-components'
import {
  media,
  textSm,
  textBase,
  colors,
  fonts,
  textMd,
} from '../../../../lib/constants'

export const Description = styled.p`
  margin-bottom: 3.75rem;
  ${textMd}

  ${media.tablet`
    ${textBase}
    ${({ hasVideo }) => (!hasVideo ? `margin-bottom: 0;` : '')}
  `}
`
export const Content = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    font-size: 36px;
    font-family: ${fonts.headings};
    color: ${colors.blue};
  }

  a:not(.nounderline)::before {
    left: 0;
    width: 100%;
  }
`
