import styled from 'styled-components'
import { fonts, colors, media } from '../../../../../lib/constants'
import { Link } from 'gatsby'

export const ProjectInfo = styled.div``

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 3px solid ${colors.pink};
  padding-top: 60px;
  padding-bottom: 30px;
  margin-bottom: 30px;

  ${media.tablet`
    flex-direction: column-reverse;
    text-align: center;

    ${ProjectInfo} {
      margin-bottom: 2.5rem;
    }
  `}
`

export const ProjectCategory = styled.h2`
  font-weight: 700;
  text-transform: uppercase;
  color: ${colors.pink};
  margin-bottom: 13px;
  font-size: 18px;
`

export const ProjectName = styled.h2`
  font-size: 48px;
  font-weight: 700;
  font-family: ${fonts.headings};
  margin-bottom: 20px;
  color: ${colors.blue};

  ${media.tablet`
    font-size: 28px;
  `}
`

export const ProjectLink = styled(Link)`
  color: ${colors.pink};
  font-size: 24px;

  &:not(.nounderline)::before {
    max-height: 1px;
    background-color: ${colors.pink};
  }

  ${media.tablet`
    font-size: 18px;
  `}
`

export const ClientInfo = styled.div`
  text-align: center;
  color: ${colors.white};
`

export const ClientLogo = styled.img`
  margin-bottom: 16px;
  min-width: 60px;
  max-height: 60px;
`

export const ClientName = styled.span`
  display: block;
  font-family: ${fonts.headings};
  font-weight: 700;
  line-height: 1;
  color: ${colors.white};
`
