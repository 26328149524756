import styled from 'styled-components'
import { colors, fonts, media, shadows } from '../../../../lib/constants'

export const ImageSlider = styled.section`
  background-color: ${colors.white};
  padding: 3rem 0 5rem;

  ${media.desktop`
    padding-top: 2.5rem;
    padding-bottom: 3.875rem;
  `}

  ${media.desktop`
    padding-top: 2.5rem;
    padding-bottom: 3.625rem;
  `}
`

export const SliderItem = styled.div`
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;

  ${media.tablet`
    margin: 15px;
  `}

  img {
    display: block;
    max-height: 60vh !important;
    border-radius: 10px;
    box-shadow: ${shadows.projectCard};

    ${media.desktop`
      height: 300px !important;
    `}

    ${media.tablet`
      height: 200px !important;
    `}
  }
`

export const Indicator = styled.div`
  font-family: ${fonts.headings};
  font-size: 1.3888rem;
  color: ${colors.grey__light};
  margin-right: 1.625rem;
  cursor: pointer;
  white-space: nowrap;

  ${media.tablet`
    font-size: 1rem;
    margin-right: 0.625rem;
  `}
`

export const CarouselWrap = styled.div`
  .slick-slider {
    ${media.desktop`
      height: 370px !important;

      .slick-dots {
        bottom: -20px;
      }
    `}

    ${media.tablet`
      height: 240px !important;

      .slick-dots {
        bottom: -25px;
      }
    `}
  }

  .slick-dots {
    li {
      width: auto;
      margin-right: 0;

      span {
        display: none;
      }

      &::before {
        display: none;
      }

      &.slick-active {
        ${Indicator} {
          color: ${colors.blue};
        }

        span {
          display: inline-block;
        }
      }
    }
  }
`
